import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PappersService {
  private http = inject(HttpClient);
  private cacheService = inject(CacheService);
  private pappersApiUrl = 'https://suggestions.pappers.fr/v2?q=';

  constructor() {}

  public searchCompanies(searchStr: string): Observable<any[]> {
    if (!searchStr || searchStr.length === 0) {
      return of([]);
    }

    if (this.cacheService.get(this.pappersApiUrl, searchStr)) {
      return of(this.cacheService.get(this.pappersApiUrl, searchStr));
    }

    return this.http.get<any>(`https://suggestions.pappers.fr/v2?q=${searchStr}`).pipe(
      catchError(() => of([])),
      tap(response => {
        this.cacheService.set(this.pappersApiUrl, searchStr, response);
      })
    );
  }
}

export const PAPPERS_DATA_KEY = 'resultats_nom_entreprise';
