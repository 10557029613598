<div class="row">
  <div class="col">
    <h5>Recherche</h5>
  </div>
</div>
<ng-container *ngIf="conditions?.length && form">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-xl-4" *ngFor="let condition of conditions; let i = index">
      <div class="condition">
        {{ condition.criterion | criterion : criterions }} {{ condition.operator | searchOperator }} "{{ condition.searchValue | searchValue : condition.type }}"
        <span class="close" (click)="deleteCondition(i)"></span>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="form">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-xl-3">
      <app-dropdown-form-control
        [parentForm]="form"
        [controlName]="'criterion'"
        (onChange)="onCriterionChange($event)"
        [title]="''"
        [placeholder]="'Critère'"
        class="no-title no-validation-borders"
      ></app-dropdown-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-2">
      <app-dropdown-form-control [parentForm]="form" [controlName]="'operator'" [title]="''" [placeholder]="'Opérateur'" class="no-title no-validation-borders"></app-dropdown-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-3" *ngIf="selectedCriterionType === SearchCriterionsDataType.String">
      <app-name-with-digits-form-control
        [parentForm]="form"
        [controlName]="'searchValue'"
        [title]="''"
        [placeholder]="'Valeur'"
        class="no-title no-validation-borders"
      ></app-name-with-digits-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-3" *ngIf="selectedCriterionType === SearchCriterionsDataType.Integer || selectedCriterionType === SearchCriterionsDataType.Decimal">
      <app-number-form-control [parentForm]="form" [controlName]="'searchValue'" [title]="''" [placeholder]="'Valeur'" class="no-title no-validation-borders"></app-number-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-3" *ngIf="selectedCriterionType === SearchCriterionsDataType.Boolean">
      <app-dropdown-form-control [parentForm]="form" [controlName]="'searchValue'" [title]="''" [placeholder]="'Valeur'" class="no-title no-validation-borders"></app-dropdown-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-3" *ngIf="selectedCriterionType === SearchCriterionsDataType.DateTime">
      <app-date-form-control [parentForm]="form" [controlName]="'searchValue'" [title]="''" class="no-title no-validation-borders"></app-date-form-control>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-xl-4 pt-1">
      <button class="btn pink mr-3" [disabled]="!isSearchAvailable" (click)="addCondition()">Ajouter le critère</button>
      <button class="btn pink mr-3" (click)="clearFilters()" [disabled]="isClearFiltersDisabled">Effacer tous les critères</button>
    </div>
  </div>
</div>
