import { SuperAdminGuard } from './shared/guards/super-admin.guard';
import { MainTaxesService } from './shared/components/forms/taxes/main-taxes-form/main-taxes-form.service';
import { OtherToolsService } from './shared/components/forms/volumes/autres-outils-form/other-tools-form.service';
import {
  ExpensesClient,
  OtherStreamsClient,
  OtherToolsClient,
  PurchasesClient,
  AccountingClient,
  AdditionalInfoClient,
  API_BASE_URL,
  BeneficiariesClient,
  ContactClient,
  GeneralInfoClient,
  IntroductionClient,
  SalesClient,
  StakeholdersClient,
  BanksClient,
  LookupDataClient,
  MainTaxesClient,
  DebDesClient,
  ControlCenterClient,
  FeaturesTaxationClient,
  CompanyAgreementClient,
  SocialClient,
  ResponsibleManagementClient,
  TabsClient,
  FilesClient,
  EmployeeResourcesClient,
  OfficesClient,
  TemplatesClient,
  BillingTasksClient,
  UsersClient,
  PredefinedQuotationsClient,
  ActivitiesClient,
  PredefinedTaskDistributionTableClient,
  AnnexesClient,
  EngagementLetterClient,
  AccountingFirmsClient,
  AcdClient
} from './../webapi/Difference.WebApi';
import { SalesService } from './shared/components/forms/volumes/ventes-prestation-form/sales.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';

import localeFr from '@angular/common/locales/fr';

import { NgxCurrencyModule } from 'ngx-currency';
import { Router, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { constants } from './shared/constants/constants';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainPageComponent } from './components/main-page/main-page.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { ContactComponent } from './components/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { BesoinComponent } from './components/besoin/besoin.component';
import { InformationComponent } from './components/information/information.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarService } from './services/progress-bar.service';
import { VolumesComponent } from './components/volumes/volumes.component';
import { RegimeComponent } from './components/regime/regime.component';
import { ComptabiliteComponent } from './components/comptabilite/comptabilite.component';
import { SocialComponent } from './components/social/social.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './components/footer/footer.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilesComponent } from './components/files/files.component';
import { ContactService } from './shared/components/forms/contact/contact-second-form/contact.service';
import { GeneralInfoService } from './shared/components/forms/contact/contact-main-form/general-info.service';
import { AdminComponent } from 'projects/difference-admin/app/admin.component';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IntroductionService } from './shared/components/forms/besoin-form/introduction.service';
import { BeneficiariesService } from './shared/components/forms/benificiaries-form/benificiaries-form.service';
import { AdditionalInfoService } from './shared/components/forms/info/info-form/additional-info.service';
import { StakeholdersService } from './shared/components/forms/info/info-other-form/stakeholders.service';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { PurchasesService } from './shared/components/forms/volumes/achats-form/purchases.service';
import { ExpensesService } from './shared/components/forms/volumes/notes-de-frais-form/expenses.service';
import { OtherStreamsService } from './shared/components/forms/volumes/autres-flux-form/other-streams-form.service';
import { BanksService } from './shared/components/forms/volumes/banques-form/banks-form.service';
import { LookupDataService } from './services/lookup-data.service';
import { DebDesService } from './shared/components/forms/taxes/deb-des-form/deb-des-form.service';
import { ControlCenterService } from './shared/components/forms/taxes/control-center-form/control-center-form.service';
import { FeaturesTaxationService } from './shared/components/forms/taxes/features-of-taxation-form/features-taxation-form.service';
import { CompanyAgreementService } from './shared/components/forms/social/social-top-form/company-agreement-form.service';
import { SocialService } from './shared/components/forms/social/social-middle-form/social-form.service';
import { ResponsibleManagementService } from './shared/components/forms/social/social-bottom-form/responsible-management-form.service';
import { DataTablesModule } from 'angular-datatables';
import { AccountingService } from './shared/components/forms/accounting/organisation-comptable-form/accounting-form.service';
import { CustomerDataService } from './services/customer-data.service';
import { UserGuard } from './shared/guards/user.guard';
import { FilesService } from './shared/components/forms/files/files-form/files-form.service';
import { FileSaverModule } from 'ngx-filesaver';
import { AuthGuard } from 'projects/difference-admin/app/auth/auth.guard';
import { AuthService } from 'projects/difference-admin/app/auth/auth.service';
import { AuthInterceptor } from 'projects/difference-admin/app/auth/auth.interceptor';
import { PublicAuthInterceptor } from 'projects/difference-admin/app/auth/public-auth.interceptor';
import { TokenStorageService } from 'projects/difference-admin/app/auth/token-storage.service';
import { SelectedCompanyDataService } from './services/selected-company-data.service';
import { MatIconModule } from '@angular/material/icon';
import { AccountingFirmsService } from './services/accounting-firms.service';
import { JeSignTokensDataService } from './services/je-sign-tokens-data.service';
import { AcdService } from './services/acd.service';
import { StoreModule } from '@ngrx/store';
import { NotificationsModule } from '@components/notifications/notifications.module';
import { EffectsModule } from '@ngrx/effects';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { CacheService } from 'projects/difference-admin/app/services/cache.service';
import { PappersService } from 'projects/difference-admin/app/services/pappers.service';

const routes: Routes = [
  { path: '', data: { name: '', roles: ['diff'] }, loadChildren: () => import('../bundles/diff/diff.module').then(m => m.DiffModule) },
  { path: '', data: { name: '', roles: ['admin'] }, loadChildren: () => import('../../difference-admin/app/admin.module').then(m => m.AdminModule) }
];

registerLocaleData(localeFr);

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    if (style === 'long') {
      return ['', '', '', '', '', '', ''];
    } else {
      return ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'];
    }
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: ['DD/MM/YYYY']
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    MainPageComponent,
    ContactComponent,
    HeaderComponent,
    MenuComponent,
    BesoinComponent,
    InformationComponent,
    ProgressBarComponent,
    VolumesComponent,
    RegimeComponent,
    ComptabiliteComponent,
    SocialComponent,
    FooterComponent,
    FilesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    ModalModule.forRoot(),
    SharedComponentsModule,
    MatCheckboxModule,
    NoopAnimationsModule,
    DirectivesModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatAutocompleteModule,
    DataTablesModule,
    FileSaverModule,
    MatIconModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    NotificationsModule
  ],
  exports: [],
  providers: [
    AuthGuard,
    AuthService,
    TokenStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: initAuthInterceptor,
      deps: [Router, AuthService, TokenStorageService, JeSignTokensDataService],
      multi: true
    },
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    [
      {
        provide: APP_INITIALIZER,
        useFactory: initApp,
        deps: [Injector, LookupDataService, JeSignTokensDataService],
        multi: true
      }
    ],
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig
    },
    CurrencyPipe,
    ProgressBarService,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MATERIAL_DATEPICKER_FORMATS
    },
    CustomerDataService,
    LookupDataService,
    JeSignTokensDataService,
    LookupDataClient,
    ContactClient,
    ContactService,
    GeneralInfoClient,
    GeneralInfoService,
    IntroductionClient,
    IntroductionService,
    BeneficiariesClient,
    BeneficiariesService,
    AdditionalInfoClient,
    AdditionalInfoService,
    StakeholdersService,
    StakeholdersClient,
    AccountingService,
    AccountingClient,
    SalesClient,
    SalesService,
    PurchasesClient,
    PurchasesService,
    ExpensesClient,
    ExpensesService,
    OtherStreamsClient,
    OtherStreamsService,
    OtherToolsClient,
    OtherToolsService,
    BanksService,
    BanksClient,
    MainTaxesClient,
    MainTaxesService,
    DebDesService,
    DebDesClient,
    ControlCenterService,
    ControlCenterClient,
    FeaturesTaxationService,
    FeaturesTaxationClient,
    CompanyAgreementService,
    CompanyAgreementClient,
    SocialService,
    SocialClient,
    ResponsibleManagementService,
    ResponsibleManagementClient,
    FilesClient,
    FilesService,
    TabsClient,
    UserGuard,
    SuperAdminGuard,
    EmployeeResourcesClient,
    OfficesClient,
    TemplatesClient,
    BillingTasksClient,
    UsersClient,
    PredefinedQuotationsClient,
    ActivitiesClient,
    PredefinedTaskDistributionTableClient,
    SelectedCompanyDataService,
    AnnexesClient,
    EngagementLetterClient,
    AccountingFirmsClient,
    AccountingFirmsService,
    AcdClient,
    AcdService,
    CacheService,
    PappersService
  ],
  bootstrap: [environment.orgId !== constants.orgId.admin.value ? AppComponent : AdminComponent]
})
export class AppModule {
  constructor(private modalService: BsModalService) {
    this.modalService.config.keyboard = true;
    this.modalService.config.backdrop = 'static';
    this.modalService.config.ignoreBackdropClick = true;
  }
}

export function initApp(injector: Injector, lookupDataService: LookupDataService, jeSignTokensDataService: JeSignTokensDataService) {
  return () => {
    return new Promise<void>(async resolve => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter(r => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      await lookupDataService.initliaze();
      await jeSignTokensDataService.initliaze();
      resolve();
    });
  };
}

export function initAuthInterceptor(router: Router, authService: AuthService, tokenStorageService: TokenStorageService, jeSignTokensDataService: JeSignTokensDataService): HttpInterceptor {
  switch (environment.orgId) {
    case constants.orgId.admin.value:
      return new AuthInterceptor(router, authService, jeSignTokensDataService);
    default:
      return new PublicAuthInterceptor(tokenStorageService);
  }
}
