<div *ngIf="otherFlows">
  <div *ngFor="let otherFlowForm of otherFlows.controls; index as i" class="row">
    <div [formGroup]="otherFlowForm" *ngIf="otherFlowForm" class="col">
      <div class="col-12 justify-content-center title-with-del">
        <h5>Autre flux {{ i + 1 }}</h5>
        <img *ngIf="isEditMode" src="../../../../../../assets/img/delete-icon.svg" class="icon-in-label red" alt="" (click)="deleteOtherFlow(i)" />
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.otherStreamsForm.natureFlex, config)">
          <app-name-with-digits-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.natureFlex, config)"
            [title]="'Nature du flux'"
            [placeholder]="'Saisissez la nature de ce flux'"
          >
          </app-name-with-digits-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-xl-8" *ngIf="isShowControl(formFields.otherStreamsForm.comments, config)">
          <app-name-with-digits-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.comments, config)"
            [title]="'Commentaires'"
            [placeholder]="'Décrivez ce flux'"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.otherStreamsForm.periodId, config)">
          <app-dropdown-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.periodId, config)"
            [title]="'Période'"
            [placeholder]="'Sélectionnez une période'"
          >
          </app-dropdown-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.otherStreamsForm.numberPerPeriod, config)">
          <app-number-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.numberPerPeriod, config)"
            [title]="numberPerPeriodTitle"
            [placeholder]="'Saisissez le nombre de factures'"
          >
          </app-number-form-control>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4" *ngIf="isShowControl(formFields.otherStreamsForm.formatId, config)">
          <app-dropdown-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.formatId, config)"
            [title]="'Format des pièces'"
            [placeholder]="'Sélectionnez un format'"
          >
          </app-dropdown-form-control>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-6" *ngIf="isShowControl(formFields.otherStreamsForm.hasManagementTool, config)">
          <app-radio-group-form-control
            (onChange)="onHasManagementToolChanged($event, otherFlowForm)"
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.hasManagementTool, config)"
            [title]="hasToolTitle"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
        <div class="col-12 col-xl-6" *ngIf="isSalesManagementToolFalse(otherFlowForm) && isShowControl(formFields.otherStreamsForm.IsIntrestedInManagementTool, config)">
          <app-radio-group-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.IsIntrestedInManagementTool, config)"
            [title]="'Êtes-vous intéressé par un outil de ce type ? '"
            [inline]="true"
          >
          </app-radio-group-form-control>
        </div>
      </div>

      <div class="row" *ngIf="isSalesManagementToolTrue(otherFlowForm) && isShowControl(formFields.otherStreamsForm.toolName, config)">
        <div class="col-12 col-sm-12 col-md-4 col-xl-4">
          <app-name-with-digits-form-control
            [parentForm]="otherFlowForm"
            [controlName]="getControlName(formFields.otherStreamsForm.toolName, config)"
            [title]="toolsTitle"
            [placeholder]="'Nom du ou des outils'"
          >
          </app-name-with-digits-form-control>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isEditMode">
    <div class="col-12 text-end">
      <button class="btn pink w100mobile one-in-col" (click)="addNewOtherFlow()">
        <img src="../../../../../../assets/img/plus-icon.svg" alt="" />
        Ajouter un flux
      </button>
    </div>
  </div>
</div>
