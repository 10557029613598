import { NameWithDigitsFormControl } from 'projects/difference/app/shared/components/controls/name-with-digits-form-control/name-with-digits-form-control';
import { RadioGroupFormControl } from './../../../controls/radio-group-form-control/radio-group-form-control';
import { FormMode } from './../../../../../../../difference-admin/app/shared/models/form-modes';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AbstractForm, FormValuesChangedModel } from '../../abstract-form';
import { OtherStreamsService } from './other-streams-form.service';
import { DataFieldConfig, OtherStreams } from 'projects/difference/webapi/Difference.WebApi';
import { getOtherStreamsFormComponentControl, otherStreamsFormToViewModel } from './other-streams-form.extensions';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';

@Component({
  selector: 'app-autres-flux-form',
  templateUrl: './autres-flux-form.component.html',
  styleUrls: ['./autres-flux-form.component.scss']
})
export class AutresFluxFormComponent extends AbstractForm<OtherStreams[]> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public numberPerPeriodTitle: string = '';
  public hasToolTitle: string = '';
  public toolsTitle: string = '';
  public isIntrestedInManagementToolControl: RadioGroupFormControl;
  public toolNameControl: NameWithDigitsFormControl;

  constructor(public bsModalService: BsModalService, public otherStreamsService: OtherStreamsService, public lookupDataService: LookupDataService, public customerDataService: CustomerDataService) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.otherStreamsService.getConfig();
    this.formData = await this.otherStreamsService.get(this.profileGuid);
    this.formId = this.formData?.map((ben: OtherStreams) => ben.volumeOtherStreamId);
  }

  hasCompanyOrNotProcessing() {
    this.numberPerPeriodTitle = this.customerDataService.hasCompanyValue ? 'Nombre de pièces par période' : 'Nombre de pièces prévisionnel par période (si connu)';
    this.hasToolTitle = this.customerDataService.hasCompanyValue ? 'Disposez-vous d’un outil de gestion dédié à ce flux ? ' : 'Disposerez-vous d’un outil de gestion dédié à ce flux ?';
    this.toolsTitle = this.customerDataService.hasCompanyValue ? 'Quel(s) outil(s) utilisez-vous ? ' : 'Quel(s) outil(s) utiliserez-vous ?';
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      otherFlows: new FormArray([])
    });
  }

  onHasManagementToolChanged(value: boolean, fg: FormGroup): void {
    this.isIntrestedInManagementToolControl = fg.get(formFields.otherStreamsForm.IsIntrestedInManagementTool) as RadioGroupFormControl;
    this.toolNameControl = fg.get(formFields.otherStreamsForm.toolName) as NameWithDigitsFormControl;

    this.toolNameControl.markRequiredOrNot(value);
    this.isIntrestedInManagementToolControl.markRequiredOrNot(!value);

    if (value) {
      this.isIntrestedInManagementToolControl.patchValue(null);
    } else {
      this.toolNameControl.patchValue(null);
    }

    fg.updateValueAndValidity();

    const data = { model: this.form.getRawValue(), validState: false, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
    setTimeout(() => {
      this.onFormValuesChanged.emit(data);
    });
  }

  restoreAnswers(): void {
    if (this.formData?.length > 0) {
      const countOfForms = this.formData.length;
      let i = 0;

      while (i < countOfForms) {
        this.addNewOtherFlow(this.formData[i]);
        i++;
      }

      const viewModel = otherStreamsFormToViewModel(this.config, this.formData);
      this.otherFlows.patchValue(viewModel);
    }
    this.form.markAsPristine();
    this.onFormDataLoaded.next(true);
  }

  get otherFlows(): FormArray {
    return this.form?.get('otherFlows') as FormArray;
  }

  addNewOtherFlow(otherFlow?: OtherStreams): void {
    const newFormGroup = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getOtherStreamsFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (configItem.name === formFields.otherStreamsForm.IsIntrestedInManagementTool) {
        if (otherFlow && otherFlow.hasManagementTool !== undefined && otherFlow.hasManagementTool !== null) {
          const hasManagementTool = !!otherFlow.hasManagementTool;
          const isIntrestedInManagementToolControl = controlToBeAdded as RadioGroupFormControl;
          isIntrestedInManagementToolControl.markRequiredOrNot(!hasManagementTool);
        }
      }

      if (configItem.name === formFields.otherStreamsForm.toolName) {
        if (otherFlow && otherFlow.hasManagementTool !== undefined && otherFlow.hasManagementTool !== null) {
          const hasManagementTool = !!otherFlow.hasManagementTool;
          const toolNameControl = controlToBeAdded as NameWithDigitsFormControl;
          toolNameControl.markRequiredOrNot(hasManagementTool);
        }
      }

      if (controlToBeAdded) {
        newFormGroup.addControl(configItem.name, controlToBeAdded);
      }
    });

    this.otherFlows.push(newFormGroup);
    this.otherFlows.updateValueAndValidity();
  }

  isSalesManagementToolFalse(form: FormGroup): boolean {
    return form.get(formFields.otherStreamsForm.hasManagementTool)?.value === false;
  }

  isSalesManagementToolTrue(form: FormGroup): boolean {
    return form.get(formFields.otherStreamsForm.hasManagementTool)?.value === true;
  }

  isAllFormsValidAfterDeletion(): boolean {
    let isValid = true;

    this.otherFlows.controls.forEach((form: FormGroup) => {
      if (!this.isHasManagementToolPristine(form)) {
        isValid = form.valid;
      } else {
        isValid = form.pristine ? true : form.valid;
      }
    });

    return isValid;
  }

  isHasManagementToolPristine(form: FormGroup): boolean {
    return form?.get(formFields.otherStreamsForm.hasManagementTool)?.pristine === true;
  }

  async deleteOtherFlow(index: number): Promise<void> {
    const deleteText = 'Etes-vous sûr de vouloir supprimer ?';
    const modal = this.bsModalService.show(ConfirmModalComponent, {
      initialState: { body: deleteText }
    });

    modal.content.onClose.subscribe(async (isConfirmed: boolean) => {
      if (isConfirmed) {
        this.otherFlows.removeAt(index);
        const isValid = this.isAllFormsValidAfterDeletion();
        this.manualNotifyAboutModelChanges(this.form.getRawValue(), isValid, true);
      }
    });
  }
}
