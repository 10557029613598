import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BulkOperationResult } from 'projects/difference/webapi/Difference.WebApi';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeleteErrorModalComponent } from '../../../difference-admin/app/components/devis/modals/delete-error-modal/delete-error-modal.component';
import { Store } from '@ngrx/store';
import { NotificationsActions } from '@components/notifications/store/actions';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private bsModalService = inject(BsModalService);
  private store = inject(Store);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 409: {
              this.processingError409(err);
              return throwError(err);
            }
            case 400: {
              this.processingError400(err);
              return throwError(err);
            }
            default: {
              return throwError(err);
            }
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private processingError400(err: any): void {
    const reader = new FileReader();
    const store = this.store;

    reader.onload = function () {
      const data = JSON.parse(reader.result + '');
      let message = 'L’opération n’a pas pu être terminée. ';

      if (data.errors?.Name) {
        data.errors.Name.forEach((error: any) => {
          message += error || '';
          message += ' ';
        });
      } else {
        message += data.error;
      }

      store.dispatch(
        NotificationsActions.shownotification({
          message,
          action: 'x'
        })
      );
    };

    if (err.error) {
      reader.readAsText(err.error);
    } else {
      let message = 'L’opération n’a pas pu être terminée. ';
      message += err?.message || 'HttpErrorResponse 400';

      this.store.dispatch(
        NotificationsActions.shownotification({
          message: this.processingError400Message(message),
          action: 'x'
        })
      );
    }
  }

  private processingError400Message(message: string): string {
    if (message?.indexOf(ERROR_400_TYPE.UNIVERSIGN_CERTIFICATE_NOT_VALID) !== -1) {
      message = ERROR_400_MESSAGE.UNIVERSIGN_CERTIFICATE_NOT_VALID;
    }

    return message;
  }

  private processingError409(err: any): void {
    if (err) {
      err.error.text().then((text: string) => {
        const data = JSON.parse(text) as BulkOperationResult;
        this.show409ErrorModal(data);
      });
    }
  }

  private show409ErrorModal(data: BulkOperationResult) {
    if (data?.failedOperations?.length > 0) {
      const blockedBy = data?.failedOperations[0].errorMessage;

      this.bsModalService.show(DeleteErrorModalComponent, {
        initialState: {
          body: `La suppression de ce(s) élément(s) ne peut pas être réalisée car ils sont en cours d'utilisation par la ressource suivante : ${blockedBy}. <br/><br/> 
              Supprimez cette dépendance puis essayez à nouveau.`
        },
        class: 'modal-dialog-centered'
      });
    }
  }
}

export enum ERROR_400_TYPE {
  UNIVERSIGN_CERTIFICATE_NOT_VALID = 'UNIVERSIGN_CERTIFICATE_NOT_VALID'
}

export enum ERROR_400_MESSAGE {
  UNIVERSIGN_CERTIFICATE_NOT_VALID = 'L’opération n’a pas pu être terminée car l’adresse email et/ou le numéro de téléphone utilisé ne correspondent pas à ceux enregistrés auprès de Jesignexpert et Universign.'
}
