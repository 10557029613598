import { NumberFormControl } from './../../controls/number-form-control/number-form-control';
import { DateFormControl } from './../../controls/date-form-control/date-form-control';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseSearchComponent } from 'projects/difference/app/shared/components/data-table/base-search-component/base-search.component';
import { DropDownFormControl } from 'projects/difference/app/shared/components/controls/dropdown-form-control/dropdown-form-control';
import { NameWithDigitsFormControl } from 'projects/difference/app/shared/components/controls/name-with-digits-form-control/name-with-digits-form-control';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { constants } from 'projects/difference/app/shared/constants/constants';
import { MatSelectChange } from '@angular/material/select';
import { SearchCriterionsDataType } from '../models/search-criterions-data-type';
import { SearchOperator } from '../models/search-operator-model';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionHandler } from '@components/subscriptionHandler';

@Component({
  selector: 'app-data-table-search',
  templateUrl: './data-table-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableSearchComponent extends BaseSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() reApplyFilters: BehaviorSubject<boolean> | null;
  @Input() criterions: Criterion[];
  @Input() eventService: any;
  @Input() webApi: any;
  @Input() withAlertTypeFiltering: boolean;

  private subscriptionHandler: SubscriptionHandler;
  public form: FormGroup;
  public conditions: SearchItemModel[] = [];
  public criterionFormControl: DropDownFormControl;
  public operatorFormControl: DropDownFormControl;
  public selectedCriterionType: number;
  public SearchCriterionsDataType = SearchCriterionsDataType;
  public selectedCriterionEntity: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscriptionHandler = new SubscriptionHandler();
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.reApplyFilters?.subscribe((apply: boolean) => {
      if (apply === true) {
        this.search();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionHandler.unsubscribeAll();
  }

  checkAndRestoreFilters() {
    const filters = this.eventService.getFilters();
    if (filters) {
      this.addCondition(filters);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.criterions && changes?.criterions.currentValue !== undefined) {
      this.eventService?.setFilterCriterions(this.criterions);
      this.initNewFormGroup();
      this.initControls();
      this.conditions = [];

      this.checkAndRestoreFilters();
    }
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      criterion: new DropDownFormControl(false, false, {
        valuePropertyName: constants.titlePropertyName,
        keyPropertyName: constants.keyPropertyName,
        collection: this.criterions ?? []
      }),
      operator: new DropDownFormControl(false, false, {
        valuePropertyName: constants.valuePropertyName,
        keyPropertyName: constants.keyPropertyName,
        collection: collections.allSearchOperatorsCollection
      })
      // searchValue: new NameWithDigitsFormControl(true, false)
    });
  }

  initControls(): void {
    this.criterionFormControl = this.form.get('criterion') as DropDownFormControl;
    this.operatorFormControl = this.form.get('operator') as DropDownFormControl;
  }

  onCriterionChange(data: MatSelectChange): void {
    const selecedCriterionId = data.value;
    const operatorsCollection = this.initOperatorsCollection(selecedCriterionId);
    if (operatorsCollection) {
      this.checkOperators(operatorsCollection);
    }
  }

  initOperatorsCollection(selecedCriterionId: number): SearchOperator[] {
    const selectedCriterion = this.criterions.filter((criterion: Criterion) => {
      return criterion.id === selecedCriterionId;
    })[0];

    if (!selectedCriterion) {
      return null;
    }

    this.selectedCriterionType = selectedCriterion.type;
    this.selectedCriterionEntity = selectedCriterion.entity;

    const operatorsCollection = this.processCriterionType();
    this.operatorFormControl.setCollection(operatorsCollection);

    return operatorsCollection;
  }

  checkOperators(operatorsCollection: SearchOperator[]): void {
    const currentOperatorId = this.operatorFormControl.value;

    if (currentOperatorId !== null) {
      if (
        !operatorsCollection.some((operator: SearchOperator) => {
          return operator.id === currentOperatorId;
        })
      ) {
        this.operatorFormControl.patchValue(null);
      }
    }
  }

  processCriterionType(): SearchOperator[] {
    this.form.removeControl('searchValue');

    switch (this.selectedCriterionType) {
      case SearchCriterionsDataType.String: {
        this.form.addControl('searchValue', new NameWithDigitsFormControl(false, false));
        return collections.stringSearchOperatorsCollection;
      }
      case SearchCriterionsDataType.Integer: {
        this.form.addControl('searchValue', new NumberFormControl(false, false));
        return collections.numberSearchOperatorsCollection;
      }
      case SearchCriterionsDataType.Decimal: {
        this.form.addControl('searchValue', new NumberFormControl(false, false));
        return collections.numberSearchOperatorsCollection;
      }
      case SearchCriterionsDataType.DateTime: {
        this.form.addControl('searchValue', new DateFormControl(false, false));
        return collections.numberSearchOperatorsCollection;
      }
      case SearchCriterionsDataType.Boolean: {
        this.form.addControl(
          'searchValue',
          new DropDownFormControl(false, false, {
            collection: collections.activeDeactiveBoolean,
            valuePropertyName: constants.namePropertyName,
            keyPropertyName: constants.keyPropertyName
          })
        );
        return collections.booleanSearchOperatorsCollection;
      }
      default: {
        this.form.addControl('searchValue', new NameWithDigitsFormControl(true, false));
        return collections.allSearchOperatorsCollection;
      }
    }
  }

  initSearchCriterionsCollection(): void {
    if (this.conditions?.length === 0) {
      this.criterionFormControl.setCollection(this.criterions);
      return;
    }

    let criterionsCollectionFiltered = Object.assign(this.criterions);

    this.conditions.forEach((alreadySelectedCriterion: SearchItemModel) => {
      criterionsCollectionFiltered = criterionsCollectionFiltered.filter((criterion: Criterion) => {
        return criterion.id !== alreadySelectedCriterion.criterion;
      });
    });

    this.criterionFormControl.setCollection(criterionsCollectionFiltered);
  }

  addCondition(conditions?: SearchItemModel[]): void {
    if (conditions) {
      this.conditions = this.conditions.concat(conditions);
    } else {
      this.conditions.push({ ...this.form.getRawValue(), type: this.selectedCriterionType, entity: this.selectedCriterionEntity } as SearchItemModel);
    }
    this.clearForm();
    this.search();
    this.initSearchCriterionsCollection();
  }

  deleteCondition(index: number): void {
    this.conditions.splice(index, 1);
    this.search();
    this.initSearchCriterionsCollection();
  }

  public search(): void {
    this.eventService.resetPagination();
    this.eventService.onFilter(this.conditions);
  }

  clearForm(): void {
    this.form.reset();
  }

  clearFilters(): void {
    this.clearForm();
    this.conditions = [];
    this.webApi?.clearAlertType();
    this.withAlertTypeFiltering = null;
    this.search();
    this.initSearchCriterionsCollection();
  }

  get isSearchAvailable(): boolean {
    const criterionValue = this.criterionFormControl?.value;
    const operatorValue = this.operatorFormControl?.value;
    const searchValue = this.form?.get('searchValue')?.value;

    return !!criterionValue && !!operatorValue && !!searchValue;
  }

  get isClearFiltersDisabled(): boolean {
    if (this.withAlertTypeFiltering === true) {
      return false;
    }

    return this.conditions?.length === 0;
  }
}

export class SearchItemModel {
  criterion: number; // Criterion id
  operator: number; // SearchOperator id
  searchValue: string; // value to be searched
  type: number; // SearchCriterionsDataType
  entity: string;
}

export class Criterion {
  id?: number;
  title?: string;
  data?: string[];
  type?: number; // SearchCriterionsDataType
  entity?: string;
}
