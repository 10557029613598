import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: { [key: string]: any } = {};

  get(apiUrl: string, key: string): any {
    return this.cache[apiUrl + key];
  }

  set(apiUrl: string, key: string, value: any): void {
    this.cache[apiUrl + key] = value;
  }
}
